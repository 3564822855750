import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

function isSmall() {
    return (window.innerWidth < 640 && window.innerHeight > window.innerWidth);
}
function isMobile() {
    return mobileSniff();// /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent);
}
function iPhoneSniff() {
    return /iP(ad|hone|od).*OS/.test(window.navigator.userAgent);
}
function androidSniff() {
    return /Android/.test(window.navigator.userAgent);
}
function mobileSniff() {
    return iPhoneSniff() || androidSniff();
}


navigator.sayswho = (function(){
    let ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\bOPR\/(\d+)/)
        if(tem!= null) return 'Opera '+tem[1];
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
})();
let browser = navigator.sayswho;
// console.log(navigator)
$('body').addClass(browser.split(' ')[0].toLowerCase()).addClass(navigator.platform.toLowerCase());

// require("@fancyapps/fancybox");



import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
// import MorphSVGPlugin from "gsap/MorphSVGPlugin";
// import DrawSVGPlugin from "gsap/DrawSVGPlugin";
// import CustomEase from "gsap/CustomEase";
// import SplitText from "gsap/SplitText";
// import { SlowMo } from "gsap/EasePack";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// let featuredHeading = new SplitText("#Featured h1", {type: "words"});
gsap.from("#Featured h1", {
    duration: 0.4,
    autoAlpha: 0,
    y: "+=15",
    scrollTrigger: {
        trigger: "#Featured",
        start: "top center+=80",
        toggleActions: 'play none none reverse',
    }
});
let featured = document.querySelectorAll(".featured-project");
gsap.from(featured, {
    duration: 0.4,
    autoAlpha: 0,
    y: "+=15",
    stagger: 0.1,
    scrollTrigger: {
        trigger: "#Featured",
        start: "top center-=30",
        toggleActions: 'play none none reverse',
    }
});

gsap.from(".home-services h3", {
    duration: 0.4,
    autoAlpha: 0,
    // y: "+=15",
    stagger: 0.1,
    scrollTrigger: {
        trigger: ".home-services",
        start: "top center+=80",
        toggleActions: 'play none none reverse',
    }
});
let services = document.querySelectorAll(".home-services li");
gsap.from(services, {
    duration: 0.4,
    autoAlpha: 0,
    y: "+=15",
    stagger: 0.1,
    scrollTrigger: {
        trigger: ".home-services",
        start: "top center",
        toggleActions: 'play none none reverse',
    }
});
gsap.to(".top-shapes", {
    top: "+=2500",
    scrollTrigger: {
        start: "top top",
        scrub: true,
    }
});

$('[data-scroll]').on('click', function(e){
    e.preventDefault();
    let link = $(this).attr('href'),
        offset = $(this).attr("data-offset") || 0;//$('header').height() || 0;
    // console.log(link);
    // if (menu.classList.contains('collapse')) {
    //   menu.classList.remove('collapse');
    //   bodyScrollBar.updatePluginOptions('modal', { open: false });
    // }
    gsap.to(window, {duration: 0.5, scrollTo: {y: link, offsetY: offset}, ease: 'power1.inOut'});
    return false;
  });


$('.lazy-fit').on('bestfit', function() {
    // if (isMobile()) return;
    var css;
    var ratio = $(this).width() / $(this).height();
    var pratio = $(this).parents(".carousel-comm-left__item").width() / $(this).parents(".carousel-comm-left__item").height();
    if (isMobile()) {
      css = {width:'auto', height:'100%'};
    } else {
      if (ratio >= pratio) {
        css = {width:'auto', height:'100%'};
      } else {
        css = {width:'100%', height:'auto'};
      }
    }
    $(this).css(css);
    // gsap.to(document.querySelector(".hero-wrapper__content .text"), {duration: 1, alpha: 1, delay: 0.9, onComplete: handwriteWords});
}).on('load', function() {
    $(this).trigger('bestfit');
}).trigger('bestfit');

let resizeTime,
    timeout = false,
    delta = 200;
window.addEventListener('resize', e => {
    resizeTime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(makeResize, delta);
    }

});
function makeResize() {
    if (new Date() - resizeTime < delta) {
      setTimeout(makeResize, delta);
    } else {
      timeout = false;
      $('img').trigger('bestfit');
      // $('video').trigger('bestfit');
      // if ($('.video-container video').length) $('.video-container video').get(0).play();
    }
}



import Flickity from "flickity";
let carousel = document.querySelector('.testimonials-carousel');
if (carousel) {
    let flkt = new Flickity(carousel, {
        prevNextButtons: false,
        pageDots: true,
        selectedAttraction: 0.25,
        friction: 1.50,
        //   autoPlay: true,//3000
        on: {
            // change: function(index) {
            //   let curr = this.selectedElement;
            //   cta.querySelector('span').innerHTML = curr.querySelector('figcaption').innerHTML;
            //   cta.setAttribute("href", curr.dataset.link);
            //   carouselTitle.innerHTML = curr.querySelector('figcaption').innerHTML;
            // },
            ready: function() {
                // cta.querySelector('span').innerHTML = firstItem.querySelector('figcaption').innerHTML;
                // cta.setAttribute("href", firstItem.dataset.link);
                // carouselTitle.innerHTML = firstItem.querySelector('figcaption').innerHTML;
                if (this.slides.length < 2) {
                    // console.log(this)
                    // this.prevButton.element.classList.add('hide');
                    // this.nextButton.element.classList.add('hide');
                    this.pageDots.holder.classList.add('hide');
                }
            }
        }
    });
}
